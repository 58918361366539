@import 'customBootstrap.scss';
// @import '~bootstrap/scss/bootstrap.scss';

html {
  scroll-padding-top: 75px;
}

.theme-black {
  color: $white;
  background-color: $black;
}

.cart-button:hover {
  @extend .bg-white;
}

.cart-button:focus {
  @extend .bg-white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background: $black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

@font-face {
  font-family: Bicubik;
  src: url('../assets/fonts/Bicubik.OTF')
}

header, h1 {
  font-family: Bicubik !important;
}
